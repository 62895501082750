<template>
  <div class="container">
    <el-swiper :src='imgSrc'
               :info="info"></el-swiper>
    <div class="container_ container1">
      <div class="main">
        <div class="part part1">
          <div class="text">
            <div class="p1">
              <div class="bgimg_"></div>
              <p>主要解决路内、路外、街区、商业、行政、小区等城市各种停车难问题</p>
            </div>
            <div class="p2">
              <div class="bgimg_"></div>
              <p>基于先进的物联网+互联网技术，整合智能地锁、智能地磁检测器、智能道闸、智慧停车一体化云平台、移动用户端等组件，形成了具有场景特色的智慧停车解决方案</p>
            </div>
            <div class="p3">
              <div class="bgimg_"></div>
              <p>主要包含城市路内快速停车系统、停车场内快速停车系统、快速离场系统以及车位精细化管理系统等解决方案</p>
            </div>
          </div>
          <div class="ul_">
            <div class="li_">
              <div class="left">
                <div class="bgimg_ bgimg1"></div>
                <p>运营车位</p>
              </div>
              <div class="right">
                <p class="num">10</p>
                <p>万</p>
                <p class="plus">+</p>
              </div>
            </div>
            <div class="li_">
              <div class="left">
                <div class="bgimg_ bgimg2"></div>
                <p>服务车主</p>
              </div>
              <div class="right">
                <p class="num">50</p>
                <p>万</p>
                <p class="plus">+</p>
              </div>
            </div>
            <div class="li_">
              <div class="left">
                <div class="bgimg_ bgimg3"></div>
                <p>运营停车场</p>
              </div>
              <div class="right">
                <p class="num">1000</p>
                <p class="plus_">+</p>
              </div>
            </div>
            <div class="li_">
              <div class="left">
                <div class="bgimg_ bgimg4"></div>
                <p>深度覆盖</p>
              </div>
              <div class="right">
                <p class="num">5</p>
                <p class="font_30">个省市</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container_ container2">
      <div class="main">
        <div class="part part2">
          <div class="ul_">
            <div class="li_">
              <div class="img">
                <img src="../assets/images/hxb_part2_1.png"
                     alt="">
              </div>
              <div class="text">
                <div class="title">道路外停车解决方案</div>
                <div class="content">通过智能地锁实现停车资源信息化、联网化，将开放型、零星或私人停车位整合到平台中，实现停车信息动态分享、预约停车等功能，缓解城市停车难的压力</div>
              </div>
            </div>
            <div class="li_">
              <div class="img">
                <img src="../assets/images/hxb_part2_2.png"
                     alt="">
              </div>
              <div class="text">
                <div class="title">道路内停车解决方案</div>
                <div class="content">通过先进的地刺车检器实现路内停车区域的车位诱导、车位管理、计费、占用分析报告及停车位优化等功能，有效缓解停车乱等现象</div>
              </div>
            </div>
            <div class="li_">
              <div class="img">
                <img src="../assets/images/hxb_part2_3.png"
                     alt="">
              </div>
              <div class="text">
                <div class="title">道闸车库解决方案</div>
                <div class="content">通过停车场道闸系统以及尚盈车联APP实现从车辆快速进场、快速停车、快速找车、无感支付等一系列完整的、全自动化的功能，优化停车场资源，最终实现无人管理</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container_ container3">
      <div class="main">
        <div class="part part3">
          <div class="title">四大核心功能</div>
          <div class="bgimg"></div>
          <div class="dsc">实现停车场运营升级</div>
          <ul class="ul_part3">
            <li class="li_part3">
              <img src="../assets/images/hxb_part3_1.png"
                   alt="">
              <div class="title">降本增益</div>
              <div class="content">通过车位引导、共享车位等方式，优化车位配置，提升日均使用率，增加整体营收
              </div>
            </li>
            <li class="li_part3">
              <img src="../assets/images/hxb_part3_2.png"
                   alt="">
              <div class="title">服务更优</div>
              <div class="content">车位预约、车队导航、无人值守、反向寻车、无感支付、1s进出，避免高峰拥堵
              </div>
            </li>
            <li class="li_part3">
              <img src="../assets/images/hxb_part3_3.png"
                   alt="">
              <div class="title">管理创效</div>
              <div class="content">通过尚盈车联智慧停车平台及场内视频监控，提升停车场数字化管理水平，杜绝逃单
              </div>
            </li>
            <li class="li_part3">
              <img src="../assets/images/hxb_part3_4.png"
                   alt="">
              <div class="title">精准营销</div>
              <div class="content">大数据画像分析停车行为及用户信息，助力精准营销，提升停车场客流及增值营收
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="container_ container4">
      <div class="main">
        <div class="part part4">
          <div class="title">Integrated platform</div>
          <div class="bgimg"></div>
          <div class="dsc">一体化平台 标准管理</div>
        </div>
      </div>
      <div class="img__">
      </div>
    </div>
    <div class="container_ container5">
      <div class="main">
        <div class="part part5">
          <div class="title">Efficient optimization</div>
          <div class="bgimg"></div>
          <div class="dsc">五大应用场景 高效优化</div>
          <div class="tabs_">
            <el-tabs tab-position="left"
                     style="height: 512px;">
              <el-tab-pane label="商业综合体">
                <div class="img_div">
                  <img src="../assets/images/hxb_part6_6.jpg"
                       alt="">
                </div>
              </el-tab-pane>
              <el-tab-pane label="商务写字楼">
                <div class="img_div">
                  <img src="../assets/images/hxb_part6_7.jpg"
                       alt="">
                </div>
              </el-tab-pane>
              <el-tab-pane label="公共场馆">
                <div class="img_div">
                  <img src="../assets/images/hxb_part6_8.jpg"
                       alt="">
                </div>
              </el-tab-pane>
              <el-tab-pane label="交通枢纽">
                <div class="img_div">
                  <img src="../assets/images/hxb_part6_9.jpg"
                       alt="">
                </div>
              </el-tab-pane>
              <el-tab-pane label="住宅小区">
                <div class="img_div">
                  <img src="../assets/images/hxb_part6_10.jpg"
                       alt="">
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
    </div>
    <div class="container_ container6">
      <div class="main">
        <div class="part part6">
          <div class="title">Wisdom transformation</div>
          <div class="bgimg"></div>
          <div class="dsc">智慧化改造 降本增润</div>
          <ul class="ul_">
            <li class="li_">
              <div class="bgImg_box bg1"></div>
              <div class="title">人力成本降低</div>
            </li>
            <li class="li_">
              <div class="bgImg_box bg2"></div>
              <div class="title">车流量增加</div>
            </li>
            <li class="li_">
              <div class="bgImg_box bg3"></div>
              <div class="title">车位周转率提升</div>
            </li>
            <li class="li_">
              <div class="bgImg_box bg4"></div>
              <div class="title">电子支付率提升</div>
            </li>
          </ul>
          <div class="table_">
            <table width='100%'
                   border='1'
                   cellpadding='0'
                   cellspacing='0'>
              <caption>某商业综合体改造实例</caption>
              <tr>
                <th class="th_0">
                </th>
                <th class="th_ th_1">
                  <div class="th_box">
                    <div class="img">
                      <img src="../assets/images/renlichengben.png"
                           alt="">
                    </div>
                    <div>人力成本</div>
                  </div>
                </th>
                <th class="th_ th_2">
                  <div class="th_box">
                    <div class="img">
                      <img src="../assets/images/cheliuliang.png"
                           alt="">
                    </div>
                    <div>车流量</div>
                  </div>
                </th>
                <th class="th_ th_3">
                  <div class="th_box">
                    <div class="img">
                      <img src="../assets/images/zhouzhuan.png"
                           alt="">
                    </div>
                    <div>单车位周转车次</div>
                  </div>
                </th>
                <th class="th_ th_4">
                  <div class="th_box">
                    <div class="img">
                      <img src="../assets/images/dianzizhifu.png"
                           alt="">
                    </div>
                    <div>电子支付率</div>
                  </div>
                </th>
              </tr>
              <tr>
                <td class="font_700 th_5">改造前</td>
                <td>岗亭值守人员需要5人，每年人力成本在18万元左右</td>
                <td>停车场智慧化改造前日均车流量为4600-4800车次</td>
                <td>停车场智慧化改造前的单车位日均流转车次为2.04车次</td>
                <td>未改造前商业综合体停车场电子支付率在13%左右</td>
              </tr>
              <tr>
                <td class="font_700 th_6">改造后</td>
                <td>岗亭值守人员由原来的5人减至3人,每年可以节省人力成本7万余元</td>
                <td>停车场智慧化改造后上升到至5300-5400车次</td>
                <td>该商业综合体智能停车场单车位流转率提升至 2.16车次</td>
                <td>电子支付率上升至91%，大幅提升了车辆进出的效率</td>
              </tr>
              <tr>
                <td class="font_700 th_7">效果</td>
                <td>
                  <div class="td_">
                    <div>人力节省40%</div>
                    <img src="../assets/images/hxb_part6_5.png"
                         alt="">
                  </div>
                </td>
                <td>
                  <div class="td_">
                    <div>车流量增加14%</div>
                    <img src="../assets/images/hxb_part6_6.png"
                         alt="">
                  </div>
                </td>
                <td>
                  <div class="td_">
                    <div>周转车次提升6%</div>
                    <img src="../assets/images/hxb_part6_7.png"
                         alt="">
                  </div>
                </td>
                <td>
                  <div class="td_">
                    <div>电子支付率提高6倍</div>
                    <img src="../assets/images/hxb_part6_8.png"
                         alt="">
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="container_ container7">
      <div class="main">
        <div class="part part7">
          <div class="title">四大合作模式</div>
          <div class="bgimg"></div>
          <div class="dsc">适应多种不同经营模式</div>
          <ul class="ul_">
            <li class="li_">
              <div class="img">
                <img class="img_1"
                     src="../assets/images/hxb_part7_1.png"
                     alt="">
              </div>
              <div class="text">
                <div class="title">分成合作</div>
                <div class="dsc">以分成形式合作，由好享泊提供改造硬件及平台，按前提投资比例进行分成。如只需提供平台服务，则按最低比例分成</div>
              </div>
            </li>
            <li class="li_">
              <div class="img">
                <img class="img_2"
                     src="../assets/images/hxb_part7_2.png"
                     alt="">
              </div>
              <div class="text">
                <div class="title">维护模式</div>
                <div class="dsc">由好享泊免费提供平台，并安装显示器、识别器、补光灯设备，提供巡检、维护、换货服务，按通道数量收取维护费用</div>
              </div>
            </li>
            <li class="li_">
              <div class="img">
                <img class="img_3"
                     src="../assets/images/hxb_part7_3.png"
                     alt="">
              </div>
              <div class="text">
                <div class="title">平台租赁</div>
                <div class="dsc">以单独租赁平台的形式进行合作，按道闸口收取年费，不提供运维人员维护等服务</div>
              </div>
            </li>
            <li class="li_">
              <div class="img">
                <img class="img_4"
                     src="../assets/images/hxb_part7_4.png"
                     alt="">
              </div>
              <div class="text">
                <div class="title">硬件零售</div>
                <div class="dsc">将硬件设备按市场价格零售给客户，并提供最高两年质保。质保结束需客户继续维保，则按通道数量收取年费</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      imgSrc: require('@/assets/images/banner7.png'),
      info: {
        title: '尚盈车联·好享泊',
        dsc: '城市智慧停车整体解决方案',
        number: ''
      },
      tableData: [{
        date: '改造前',
        manpower: '岗亭值守人员需要5人，每年人力成本在18万元左右',
        traffic: '停车场智慧化改造前日均车流量为4600-4800车次',
        turnoverRate: '停车场智慧化改造前的单车位日均流转车次为2.04车次',
        PaymentRate: `未改造前商业综合体停车场电子支付率在13%左右`
      }, {
        date: '改造后',
        manpower: '岗亭值守人员由原来的5人减至3人,每年可以节省人力成本7万余元',
        traffic: '停车场智慧化改造后上升到至5300-5400车次',
        turnoverRate: '该商业综合体智能停车场单车位流转率提升至 2.16车次',
        PaymentRate: '电子支付率上升至91%，大幅提升了车辆进出的效率'
      }, {
        date: '效果',
        manpower: '人力节省40%',
        traffic: '车流量增加14%',
        turnoverRate: '周转车次提升6%',
        PaymentRate: '电子支付率提高6倍'
      }]
    };
  },
  created () {

  },
  mounted () {

  },
  methods: {

  },
}
</script>
<style lang='scss' scoped>
@import "@/assets/styles/hxb.scss";
</style>